@font-face {
    font-family: "Noto Sans KR";
    font-weight: 100;
    font-style: normal;
    src: url("../fonts/NotoSans-Thin.eot?") format("eot"), url("../fonts/NotoSans-Thin.otf") format("opentype"),
        url("../fonts/NotoSans-Thin.woff") format("woff"), url("../fonts/NotoSans-Thin.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans KR";
    font-weight: 200;
    font-style: normal;
    src: url("../fonts/NotoSans-Light.eot?") format("eot"), url("../fonts/NotoSans-Light.otf") format("opentype"),
        url("../fonts/NotoSans-Light.woff") format("woff"), url("../fonts/NotoSans-Light.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans KR";
    font-weight: 300;
    font-style: normal;
    src: url("../fonts/NotoSans-DemiLight.eot?") format("eot"), url("../fonts/NotoSans-DemiLight.otf") format("opentype"),
        url("../fonts/NotoSans-DemiLight.woff") format("woff"), url("../fonts/NotoSans-DemiLight.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans KR";
    font-weight: 400;
    font-style: normal;
    src: url("../fonts/NotoSans-Regular.eot?") format("eot"), url("../fonts/NotoSans-Regular.otf") format("opentype"),
        url("../fonts/NotoSans-Regular.woff") format("woff"), url("../fonts/NotoSans-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans KR";
    font-weight: 500;
    font-style: normal;
    src: url("../fonts/NotoSans-Medium.eot?") format("eot"), url("../fonts/NotoSans-Medium.otf") format("opentype"),
        url("../fonts/NotoSans-Medium.woff") format("woff"), url("../fonts/NotoSans-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans KR";
    font-weight: 700;
    font-style: normal;
    src: url("../fonts/NotoSans-Bold.eot?") format("eot"), url("../fonts/NotoSans-Bold.otf") format("opentype"),
        url("../fonts/NotoSans-Bold.woff") format("woff"), url("../fonts/NotoSans-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans KR";
    font-weight: 900;
    font-style: normal;
    src: url("../fonts/NotoSans-Black.eot?") format("eot"), url("../fonts/NotoSans-Black.otf") format("opentype"),
        url("../fonts/NotoSans-Black.woff") format("woff"), url("../fonts/NotoSans-Black.woff2") format("woff2");
}

:root {
    --chat-background-color: white;
    --chat-close-button-color: white;
    --chat-input-background-color: white;
    --chat-input-text-color: #353535;
    --chat-input-send-button-color: #000975;
    --chat-input-placeholder-color: #aaaaaa;
    --chat-input-area-background-color: #ffffff;
    --bubble-text-color: #353535;
    --bubble-background-color: #eeeeee;
    --bubble-border-color: #eeeeee;
    --bubble-odd-text-color: #ffffff;
    --bubble-odd-background-color: #1b1b1b;
    --bubble-odd-border-color: #1b1b1b;
    --bubble-button-text-color: #000975;
    --bubble-button-background-color: #eeeeee;
    --bubble-button-border-color: #c4c4c4;
    --bubble-button-hover-color: #c9cdff;
    --bubble-time-color: #aaaaaa;
    --suggestion-button-text-color: #000975;
    --suggestion-button-border-color: #000975;
    --suggestion-button-preparing-border-color: gray;
    --suggestion-button-background-color: #ffffff;
    --suggestion-button-hover-color: #ffffff;
    --suggestion-button-preparing-text-color: gray;
    --suggestion-button-preparing-background-color: #ffffff;
    --carousel-text-color: #353535;
    --carousel-background-color: white;
    --carousel-border-color: #c4c4c4;
    --carousel-button-background-color: #ffffff;
    --carousel-button-border-color: #000975;
    --carousel-button-text-color: #000975;
    --carousel-button-hover-color: #c9cdff;
    --carousel-button-preparing-background-color: white;
    --carousel-button-preparing-text-color: gray;
    --carousel-button-preparing-border-color: gray;
    --carousel-arrow-color: #333333;
    --chat-title-background-color: #000975;
    --chat-title-text-color: #fefeff;
    --chat-feedback-background-color: white;
    --chat-feedback-text-color: #707070;
    --chat-feedback-good-button-color: #707070;
    --chat-feedback-good-button-hover-color: #000975;
    --chat-feedback-bad-button-color: #707070;
    --chat-feedback-bad-button-hover-color: #000975;
    --avatar-name-text-color: #000975;
    --show-more-color: #000975;
    --autocomplete-highlight-color: #000975;
    --autocomplete-active-color: #eeeeee;
    --popup-button-text-color: #ffffff;
    --popup-button-background-color: #000975;
    --popup-button-border-color: #000975;
    --font-size-title: 17px;
    --font-size-normal: 15px;
    --font-size-button: 14px;
    --chat-bubble-width: 260px;
}

.chat-message-popup-container {
    font-family: Noto Sans KR, Arial, sans-serif !important;
    line-height: 1.3;
    font-size: 13px;
    color: #838f9c;

    img {
        -ms-interpolation-mode: bicubic;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    table,
    pre,
    xmp,
    plaintext,
    listing,
    input,
    textarea,
    select,
    button,
    div,
    a {
        font-family: Noto Sans KR, Arial, sans-serif;
        line-height: 1.3;
    }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
        -webkit-appearance: button;
    }

    button:not(:disabled),
    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled) {
        cursor: pointer;
    }

    *,
    *::before,
    *::after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .popup-arrow-next {
        position: absolute;
        top: calc(50% - 10px);
        display: block;
        visibility: visible;
        width: 24px;
        height: 24px;
        padding: 0;
        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
        z-index: 199;
        right: 4px;
        -webkit-transform-style: preserve-3d;
        -webkit-transform: translateZ(0px);
        &.disabled {
            visibility: hidden;
        }
    }

    .popup-arrow-prev {
        position: absolute;
        top: calc(50% - 10px);
        display: block;
        visibility: visible;
        width: 24px;
        height: 24px;
        padding: 0;
        // transform: translate(0, -50%);
        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
        z-index: 199;
        left: 4px;
        -webkit-transform-style: preserve-3d;
        -webkit-transform: translateZ(0px);
        &.disabled {
            visibility: hidden;
        }
    }

    .popup-arrow {
        background: var(--carousel-arrow-color);
        color: white;
        border-radius: 12px;
        width: 24px;
        height: 24px;
        line-height: 22px;
        opacity: 0.1;
        display: flex;
        justify-content: center;

        .arrow-image {
            align-self: center;
        }

        &.disabled {
            display: none;
        }

        &:hover {
            opacity: 0.3;
        }
    }

    .text-content {
        text-align: left;
    }

    .link-button {
        text-decoration: none !important;
        border: none !important;
        background-color: transparent !important;
        display: inline;
        margin: 0;
        padding: 0;
        outline: none !important;
    }

    .bubble-button-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 8px;
    }

    .bubble-button {
        margin: 0;
        margin-top: 8px;
        overflow: hidden;
        height: 40px;
        width: 90%;
        align-self: center;
        border-radius: 20px;
        border: 1px solid var(--bubble-button-border-color);
        margin-top: 8px;
        font-size: var(--font-size-button);
        background-color: var(--bubble-button-background-color);
        color: var(--bubble-button-text-color);
        padding: 10px 14px;
        text-align: center;
        outline: none !important;
        &:hover {
            background-color: var(--bubble-button-hover-color);
        }

        &.first {
            &.no-content {
                border-top: none;
            }
        }

        &.one {
            &.no-content {
                border-top: none;
            }
        }

        .link-icon {
            align-self: center;
            margin-left: 8px;
            width: 8px;
            height: 8px;

            path {
                fill: var(--bubble-button-text-color) !important;
            }

            &.dim {
                path {
                    fill: var(--bubble-button-text-color) !important;
                }
            }
        }
    }

    .card-title {
        font-size: 15px;
        font-family: Noto Sans KR, Arial, sans-serif;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: -0.72px;
        margin-bottom: 10px !important;
        color: var(--carousel-text-color);
    }

    .card-description {
        font-size: 13px;
        font-family: Noto Sans KR, Arial, sans-serif;
        // font-family: sans-serif;
        font-weight: 300;
        line-height: 1.3;
        letter-spacing: -0.6px;
        color: var(--carousel-text-color);
    }

    .carousel-item {
        background: var(--carousel-background-color);
        color: var(--carousel-text-color);
        font-family: Noto Sans KR, Arial, sans-serif;
        vertical-align: middle;
        letter-spacing: 0px;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 16px;
        border-radius: 0px;
        display: inline-block;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .media-container {
            background-color: #eeeeee;
            margin: 0px;
            margin: -16px -16px 16px -16px;
            border-radius: 0px;
            height: 240px;

            &.audio {
                display: flex;
                div {
                    align-self: center;
                }
            }
            &.square-image {
                height: 258px;
            }

            .carousel-media {
                max-height: 400px !important;
                width: 100%;
                object-fit: cover;
                border-radius: 0px;
                height: 240px;
                outline: none;
                video {
                    border-radius: 12px 12px 0px 0px;
                    height: 145px;
                    outline: none;
                }

                &.media-only {
                    border-radius: 12px;
                    height: auto;
                    video {
                        border-radius: 12px;
                        height: auto;
                    }
                }
                &.square-image {
                    height: 258px;
                }
            }
        }
    }

    .media-container {
        background-color: #eeeeee;
        margin: 0px;
        border-radius: 0px;
        height: 240px;

        &.audio {
            display: flex;
            div {
                align-self: center;
            }
        }
        &.square-image {
            height: 258px;
        }

        .carousel-media {
            max-height: 400px !important;
            width: 100%;
            object-fit: cover;
            border-radius: 0px;
            height: 240px;
            outline: none;
            video {
                border-radius: 12px 12px 0px 0px;
                height: 145px;
                outline: none;
            }

            &.media-only {
                border-radius: 12px;
                height: auto;
                video {
                    border-radius: 12px;
                    height: auto;
                }
            }
            &.square-image {
                height: 258px;
            }
        }
    }

    .carousel-button-container {
        width: 80%;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .carousel-button:hover {
        background-color: var(--carousel-button-hover-color);
    }

    .carousel-button {
        width: 100%;
        height: 44px;
        font-family: Noto Sans KR, Arial, sans-serif;
        font-size: var(--font-size-button);
        padding: 10px 14px;
        background-color: var(--carousel-button-background-color);
        color: var(--carousel-button-text-color);
        border: 1px solid var(--carousel-button-border-color);
        line-height: 39px;
        border-radius: 16px;
        outline: none !important;
        margin-bottom: 4px;

        &.first {
            margin-top: 16px;
        }

        &.dim {
            background-color: var(--carousel-button-preparing-background-color);
            color: var(--carousel-button-preparing-text-color);
            border: 1px solid var(--carousel-button-preparing-border-color);
        }

        .link-icon {
            align-self: center;
            margin-left: 4px;
            width: 8px;
            height: 8px;

            path {
                fill: var(--carousel-button-text-color) !important;
            }

            &.dim {
                path {
                    fill: var(--carousel-button-preparing-text-color) !important;
                }
            }
        }
    }

    .button-multiline {
        white-space: pre-wrap;
        word-break: break-word;
        height: auto !important;
        line-height: 1.4;
        min-height: 40px;
    }
}

.chat-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 300;
    background-color: #fafafa;
    font-family: Noto Sans KR, Arial, sans-serif !important;
    line-height: 1.3;
    color: rgba(34, 34, 34, 0.6);

    img {
        -ms-interpolation-mode: bicubic;
    }

    .chat-player-inner-container {
        video {
            flex-basis: 160px;
        }
    }

    .nav-link {
        display: block;
        text-decoration: none;
        padding: 0.5rem 1rem;
    }
    .nav-link:hover,
    .nav-link:focus {
        text-decoration: none;
    }
    .nav-link.disabled {
        color: #6c757d;
        pointer-events: none;
        cursor: default;
    }

    .collapse:not(.show) {
        display: none;
    }

    input {
        padding: 0;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
        -webkit-appearance: button;
    }

    button:not(:disabled),
    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled) {
        cursor: pointer;
    }

    *,
    *::before,
    *::after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    table,
    pre,
    xmp,
    plaintext,
    listing,
    input,
    textarea,
    select,
    button,
    div,
    a {
        font-family: Noto Sans KR, Arial, sans-serif;
        line-height: 1.3;
    }

    b {
        font-family: Noto Sans KR, Arial, sans-serif;
        font-weight: 500;
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
    @-ms-keyframes spin {
        100% {
            -ms-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }

    .connect-refresh {
        &.spin {
            -webkit-animation: spin 1000ms infinite linear;
            -moz-animation: spin 1000ms infinite linear;
            -ms-animation: spin 1000ms infinite linear;
            animation: spin 1000ms infinite linear;
        }
    }

    .faq-show-button-container {
        display: flex;
        justify-content: center;
        width: 100%;
        position: absolute;
        bottom: 60px;
        &.show-footer {
            bottom: 84px;
        }

        .faq-show-button {
            border: none;
            display: flex;
            justify-content: center;
            background-color: black;
            border-radius: 20px;
            padding: 0px 8px;
            color: white;
            opacity: 0.2;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    .accordion-list {
        padding: 16px;
        max-height: calc(100% - 48px - 52px);
        width: 100%;
        overflow: auto;
        position: absolute;
        bottom: 48px;
        font-family: Noto Sans KR, Arial, sans-serif;
        font-size: var(--font-size-normal);
        letter-spacing: -0.64px;
        background-color: #ffffff;
        &.show-footer {
            max-height: calc(100% - 44px - 52px);
            bottom: 80px;
        }

        .accordion-list-title {
            // background-color: #c4c4c4;
            height: 40px;
            line-height: 40px;
            font-size: 13px;
            padding-left: 12px;
            font-weight: 500;
            color: #505050;
            border-bottom: 1px solid #dfe3f0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
        }

        .accordion-list-item {
            color: #505050;
            background-color: rgba(223, 227, 240, 0.4);
            height: 32px;
            line-height: 32px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            padding-left: 12px;
            cursor: pointer;
        }
    }

    .intro-container {
        border-radius: 8px;
        padding-bottom: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        z-index: 1;

        .new-message-dot-conatainer {
            display: inline-block;
            width: 18px;
            height: 18px;

            .new-message-dot {
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: red;
            }
        }

        .intro-card {
            min-height: 200px;
            margin: 8px;
            margin-top: 0px;
            background-color: white;
            border-radius: 8px;
            padding: 16px;
            overflow: hidden;
            max-height: 260px;
            // flex: "1 1",
            position: relative;
            box-shadow: 0px 2px 6px 0 rgba(34, 34, 34, 0.12);
            display: flex;
            flex-direction: column;
        }

        .event-card-slider {
            margin: 8px;
            margin-top: 0px;
            overflow: hidden;
            border-radius: 8px;
            box-shadow: 0px 2px 6px 0 rgba(34, 34, 34, 0.12);
            position: relative;
            cursor: pointer;

            .text-content {
                text-align: left;
            }

            .slider-arrow-next {
                position: absolute;
                top: calc(50% - 10px);
                display: block;
                visibility: visible;
                width: 24px;
                height: 24px;
                padding: 0;
                cursor: pointer;
                border: none;
                outline: none;
                background: transparent;
                z-index: 199;
                right: 8px;
                -webkit-transform-style: preserve-3d;
                -webkit-transform: translateZ(0px);
                &.disabled {
                    visibility: hidden;
                }
            }

            .slider-arrow-prev {
                position: absolute;
                top: calc(50% - 10px);
                display: block;
                visibility: visible;
                width: 24px;
                height: 24px;
                padding: 0;
                // transform: translate(0, -50%);
                cursor: pointer;
                border: none;
                outline: none;
                background: transparent;
                z-index: 199;
                left: 8px;
                -webkit-transform-style: preserve-3d;
                -webkit-transform: translateZ(0px);
                &.disabled {
                    visibility: hidden;
                }
            }

            .slider-arrow {
                background: var(--carousel-arrow-color);
                color: white;
                border-radius: 12px;
                width: 24px;
                height: 24px;
                line-height: 22px;
                opacity: 0.3;
                display: flex;
                justify-content: center;

                .arrow-image {
                    align-self: center;
                }

                &.disabled {
                    display: none;
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        .accordion-list {
            padding: 0;
            max-height: calc(100% - 24px);
            width: 100%;
            overflow: auto;
            position: initial;
            font-family: Noto Sans KR, Arial, sans-serif;
            font-size: var(--font-size-normal);
            letter-spacing: -0.64px;
            background-color: #ffffff;

            .accordion-list-title {
                height: 32px;
                line-height: 32px;
                font-size: 13px;
                padding-left: 12px;
                font-weight: 500;
                color: #505050;
                border-bottom: 1px solid #dfe3f0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
            }

            .accordion-list-item {
                color: #505050;
                background-color: rgba(223, 227, 240, 0.4);
                height: 32px;
                line-height: 32px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
                padding-left: 12px;
                cursor: pointer;
            }
        }
    }

    .show-more {
        color: var(--show-more-color) !important;
        text-decoration: none !important;
        background-color: transparent !important;
        font-family: Noto Sans KR, Arial, sans-serif;
        font-size: var(--font-size-normal);
        display: flex;
        margin-top: 8px;

        .show-more-arrow {
            transform: scale(0.5, 1);
            margin-left: auto;
        }
    }

    .with-550 {
        width: 550px !important;
    }

    .slick-prev {
        left: 0 !important;
    }

    .slick-next {
        right: 0 !important;
    }

    .slick-prev:before {
        color: var(--carousel-arrow-color) !important;
    }

    .slick-next:before {
        color: var(--carousel-arrow-color) !important;
    }

    .chat-custom-ui-popup-background {
        position: absolute;
        top: 52px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: calc(100% - 52px);
        display: none;
        visibility: hidden;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        z-index: 999999;

        &.show {
            display: block;
            visibility: visible;
        }
    }

    .chat-custom-ui-popup-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: calc(100% - 16px);
        max-height: calc(100% - 52px);
        padding: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -800px;
        z-index: 9999999;
        overflow: auto;
        &.show-footer {
            height: calc(100% - 52px - 32px);
        }
        opacity: 0;
        visibility: visible;

        &.show {
            opacity: 1;
            visibility: visible;
            bottom: 0px;
        }
        transition: opacity 1s, bottom 1s;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
        margin-left: 8px;
        margin-right: 8px;
    }

    .chat-custom-ui-popup-header {
        height: 20px;
        display: flex;
        justify-content: right;
        padding-top: 8px;
        padding-right: 8px;
        .link-button {
            position: absolute;
            right: 8px;
            top: 8px;
        }
    }

    .chat-custom-ui-popup-body {
        overflow: auto;
        padding: 0px 8px 8px 8px;
    }

    .chat-custom-ui-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    .chat-custom-ui {
        width: 100%;
        input {
            outline: none;
            display: block;
            width: 100%;
            height: 32px;
            padding: 0.45rem 0.9rem;
            font-size: 16px !important;
            font-weight: 400;
            line-height: 1.3;
            color: #6c757d;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #dee2e6;
            border-radius: 16px;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &.invalid {
                border: 1px solid red;
            }
        }
        .chat-custom-item {
            margin-top: 4px;
            margin-bottom: 4px;
            &.no-margin {
                margin: 0;
            }
        }

        .text {
            white-space: pre-wrap;
            word-break: break-word;
        }

        .invalid-text {
            color: red;
            white-space: pre-wrap;
            word-break: break-word;
            font-size: 10px;
        }

        .small-image {
            border-radius: 5px;
            width: 48px;
            height: 48px;
            object-fit: cover;
        }

        .large-image {
            width: 100%;
            border-radius: 12px;
        }

        .center-image {
            align-self: center;
        }

        .button-custom {
            text-decoration: none !important;
            border: none !important;
            background-color: transparent !important;
            display: inline;
            margin: 0;
            padding: 0;
            outline: none !important;
        }
        .button {
            width: 100%;
            height: 32px;
            font-family: Noto Sans KR, Arial, sans-serif;
            font-size: var(--font-size-button);
            padding: 0px 14px;
            background-color: var(--carousel-button-background-color);
            color: var(--carousel-button-text-color);
            border: 1px solid var(--carousel-button-border-color);
            margin-top: 8px;
            border-radius: 16px;
            // box-shadow: 0 2px 5px 0 #d4d4d5, 0 0 0 1px #f4f4f4;
            outline: none !important;

            &:hover {
                background-color: var(--carousel-button-hover-color);
            }

            &.first {
                margin-top: 16px;
            }

            &.dim {
                background-color: var(--carousel-button-preparing-background-color);
                color: var(--carousel-button-preparing-text-color);
                border: 1px solid var(--carousel-button-preparing-border-color);
            }

            .link-icon {
                align-self: center;
                margin-left: 4px;
                width: 8px;
                height: 8px;

                path {
                    fill: var(--carousel-button-text-color) !important;
                }

                &.dim {
                    path {
                        fill: var(--carousel-button-preparing-text-color) !important;
                    }
                }
            }
        }

        .grid-row {
            width: 100%;
            display: flex;
        }

        .grid-item {
            width: 100%;
            cursor: pointer;
            &:hover {
                background-color: var(--bubble-button-hover-color);
            }
        }

        .list-item {
            width: 100%;
            cursor: pointer;
            &:hover {
                background-color: var(--bubble-button-hover-color);
            }
        }

        .divider {
            width: 100%;
            height: 2px;
            background-color: #dadada;
        }

        .divider-image {
            width: 100%;
            height: 2px;
            object-fit: cover;
        }
    }

    .bubble-loader {
        display: flex;
        justify-content: center;
        width: 60px;
        line-height: 1.3;
        padding: 10px 14px;
        background: var(--bubble-background-color);
        border-radius: 12px;
        position: relative;
        user-select: none;
        border: 1px solid var(--bubble-border-color);
        box-sizing: content-box;
    }

    .chat-bubble {
        overflow: hidden;
        max-width: var(--chat-bubble-width);
        margin-top: 4px;
        margin-left: 8px;
        background: var(--bubble-background-color);
        padding: 0px 0px 0px 0px;
        border-radius: 12px;
        position: relative;
        // box-shadow: 0 2px 5px 0 #d4d4d5, 0 0 0 1px #f4f4f4;
        user-select: text;
        border: none;
        border: 1px solid var(--bubble-border-color);
        box-sizing: content-box;

        &.first {
            border-radius: 0px 12px 12px 12px;
            margin-top: 0px;
            &.odd {
                border-radius: 12px 12px 0px 12px;
            }
        }
        &.bubble-error-text {
          background-color: #FFEEEE;
          border: 1px solid #FF0000;
        }

        &.odd {
            background: var(--bubble-odd-background-color);
            border: 1px solid var(--bubble-odd-border-color);
        }
        &.last {
            border-radius: 12px 12px 12px 0px;
        }
        &.card {
            border: 1px solid #c4c4c4;
        }
        &.media {
            max-width: calc(var(--chat-bubble-width) + 40px);
        }
        &.show-avatar {
            margin-left: 0px;
        }
    }

    .bubble-text {
        white-space: pre-wrap;
        word-break: break-word;
        padding: 10px 14px;
    }

    .bubble-wrap {
        // font-family: sans-serif;
        font-family: Noto Sans KR, Arial, sans-serif;
        padding: 0px;
        color: var(--bubble-text-color);
        font-size: var(--font-size-normal);
        line-height: 22px;
        letter-spacing: -0.64px;
        line-height: 1.3;
        vertical-align: middle;
        &.odd {
            color: var(--bubble-odd-text-color);

            .bubble-text {
                a {
                    color: var(--bubble-odd-text-color) !important;
                }
            }
        }
    }

    .avatar {
        object-fit: cover;
        margin-right: 8px;
        &.circle {
            border-radius: 100%;
        }
    }

    .chat-list {
        background-color: var(--chat-background-color);
        list-style-type: none;
        padding: 0px 0px 8px 0px;
        margin: 0;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .bubble-image {
        max-height: 500px !important;
        object-fit: cover;
    }

    .media-image {
        object-fit: cover;
        width: 100%;
        // max-width: 360px;
    }

    .chat-button-list {
        border-radius: 20px;
        overflow: hidden;
    }

    .bubble-button-wrap {
        width: 100%;
        margin-top: 4px;
        &.no-content {
            margin-top: 0px;
        }
    }

    .bubble-button {
        overflow: hidden;
        width: 100%;
        font-size: var(--font-size-button);
        background-color: var(--bubble-button-background-color);
        color: var(--bubble-button-text-color);
        border: none;
        padding: 10px 14px;
        text-align: left;
        border-top: 1px solid var(--bubble-button-border-color);
        border-radius: 0;
        margin: 0;
        outline: none !important;
        &:hover {
            background-color: var(--bubble-button-hover-color);
        }

        &.first {
            &.no-content {
                border-top: none;
            }
        }

        &.one {
            &.no-content {
                border-top: none;
            }
        }

        .link-icon {
            align-self: center;
            margin-left: 8px;
            width: 8px;
            height: 8px;

            path {
                fill: var(--bubble-button-text-color) !important;
            }

            &.dim {
                path {
                    fill: var(--bubble-button-text-color) !important;
                }
            }
        }
    }

    .card-title {
        font-size: var(--font-size-title);
        font-family: Noto Sans KR, Arial, sans-serif;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: -0.72px;
        margin-bottom: 10px !important;
        color: var(--carousel-text-color);
    }

    .card-description {
        font-size: var(--font-size-normal);
        font-family: Noto Sans KR, Arial, sans-serif;
        // font-family: sans-serif;
        font-weight: 300;
        line-height: 1.3;
        letter-spacing: -0.6px;
        color: var(--carousel-text-color);
    }

    .kakao-card-item-table {
        font-size: var(--font-size-normal);
        font-family: Noto Sans KR, Arial, sans-serif;
        font-weight: 300;
        line-height: 1.3;
        letter-spacing: -0.6px;
        color: var(--carousel-text-color);

        padding-top: 6px;
        border-top: 1px solid var(--carousel-border-color);
        display: grid;
        grid-auto-rows: minmax(20px, auto);
        grid-template-columns: minmax(20px, auto) 1fr;
        row-gap: 6px;
        column-gap: 6px;
    }

    .kakao-card-item-title {
    }

    .kakao-card-item-description {
        font-weight: 400;
    }

    .bubble-carousel-item, .bubble-kakao-card-item {
        background: var(--carousel-background-color);
        color: var(--carousel-text-color);
        font-family: Noto Sans KR, Arial, sans-serif;
        // font-family: sans-serif;
        vertical-align: middle;
        letter-spacing: 0px;
        width: var(--chat-bubble-width) !important;
        height: auto;
        margin: 0;
        padding: 16px;
        border-radius: 12px;
        // box-shadow: 0 2px 5px 0 #d4d4d5, 0 0 0 1px #f4f4f4;
        display: inline-block;
        border: 1px solid var(--carousel-border-color);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .media-container {
            background-color: #eeeeee;
            margin: -16px -16px 16px -16px;
            border-radius: 12px 12px 0px 0px;
            height: 145px;

            &.audio {
                display: flex;
                div {
                    align-self: center;
                }
            }
            &.media-only {
                margin: -16px;
                border-radius: 12px;
                height: auto;
            }
            &.square-image {
                height: 258px;
            }

            .bubble-carousel-media, .bubble-kakao-card-media {
                max-height: 400px !important;
                width: 100%;
                object-fit: cover;
                border-radius: 12px 12px 0px 0px;
                height: 145px;
                outline: none;
                video {
                    border-radius: 12px 12px 0px 0px;
                    height: 145px;
                    outline: none;
                }

                &.media-only {
                    border-radius: 12px;
                    height: auto;
                    video {
                        border-radius: 12px;
                        height: auto;
                    }
                }

                &.square-image {
                    height: 258px;
                }
            }
        }
    }

    .bubble-carousel-button-container, .bubble-kakao-card-button-container {
        margin-top: auto;
    }
    .bubble-carousel-button, .bubble-kakao-card-button {
        width: 100%;
        height: 44px;
        font-family: Noto Sans KR, Arial, sans-serif;
        font-size: var(--font-size-button);
        padding: 10px 14px;
        background-color: var(--carousel-button-background-color);
        color: var(--carousel-button-text-color);
        border: 1px solid var(--carousel-button-border-color);
        line-height: 39px;
        margin-top: 4px;
        border-radius: 2px;
        // box-shadow: 0 2px 5px 0 #d4d4d5, 0 0 0 1px #f4f4f4;
        outline: none !important;

        &:hover {
            background-color: var(--carousel-button-hover-color);
        }

        &.first {
            margin-top: 8px;
        }

        &.dim {
            background-color: var(--carousel-button-preparing-background-color);
            color: var(--carousel-button-preparing-text-color);
            border: 1px solid var(--carousel-button-preparing-border-color);
        }

        .link-icon {
            align-self: center;
            margin-left: 4px;
            width: 8px;
            height: 8px;

            path {
                fill: var(--carousel-button-text-color) !important;
            }

            &.dim {
                path {
                    fill: var(--carousel-button-preparing-text-color) !important;
                }
            }
        }
    }

    .chat-intro-avatar {
        // width: 50px;
        // height: 50px;
        object-fit: cover;
        position: absolute;
        top: 70px;
        // left: calc(50% - 25px);
        border: none;
        &.circle {
            border-radius: 50%;
            border: 2px solid white;
        }
        z-index: 999999;
    }

    .chat-title-intro {
        flex-shrink: 0;
        z-index: 999999;
        margin-bottom: 16px;

        .chat-title-text {
            font-family: Noto Sans KR, Arial, sans-serif;
            font-weight: 500;
            line-height: normal;
            letter-spacing: normal;
            display: flex;
            //height: 40px;
            //max-width: calc(100% - 48px);
            width: 100%;
        }

        // .chat-title-text-image {
        //     width: 40px;
        //     height: 40px;
        //     margin-right: 8px;
        //     object-fit: cover;
        //     &.circle {
        //         border-radius: 50%;
        //     }
        // }

        .chat-title-image {
            left: 0;
            top: 0;
            right: 0;
            width: 100%;
            height: 80px;
            position: absolute;
            object-fit: contain;
            z-index: 0;
        }
        height: 84px !important;
        flex-shrink: 0;
        // background-image: linear-gradient(to right, var(--chat-title-background-color-start), var(--chat-title-background-color-end));
        color: var(--chat-title-text-color);
        display: flex;
        padding: 20px 0px 0px 20px;

        .close-button-svg {
            color: var(--chat-close-button-color);
            fill: var(--chat-close-button-color);
            .st0 {
                fill: var(--chat-close-button-color);
            }
            width: 16px;
            height: 16px;
            outline: none !important;
        }

        .back-button-svg {
            color: var(--chat-close-button-color);
            fill: var(--chat-close-button-color);
        }

        .logout-button-svg {
            color: var(--chat-close-button-color);
            fill: var(--chat-close-button-color);
            .st0 {
                fill: var(--chat-close-button-color);
            }
            width: 24px;
            height: 24px;
            outline: none !important;
        }
    }

    .chat-title {
        flex-shrink: 0;
        .chat-title-text {
            margin-top: 6px;
            font-family: Noto Sans KR, Arial, sans-serif;
            font-weight: 500;
            line-height: normal;
            letter-spacing: normal;
            display: flex;
            //height: 40px;
            //max-width: calc(100% - 56px);
            width: 100%;
        }
        .chat-title-text-image {
            // width: 32px;
            // height: 32px;
            margin-right: 8px;
            object-fit: cover;
            &.circle {
                border-radius: 50%;
            }
        }
        .chat-title-image {
            left: 0;
            top: 0;
            right: 0;
            width: 100%;
            height: 52px;
            position: absolute;
            object-fit: contain;
            z-index: 0;
        }
        height: 52px !important;
        flex-shrink: 0;
        // background-image: linear-gradient(to right, var(--chat-title-background-color-start), var(--chat-title-background-color-end));
        color: var(--chat-title-text-color);
        display: flex;
        padding-left: 20px;
        // padding: 5px 10px 5px 16px;
        // border-bottom: 1px solid #e9e9e9;

        .close-button-svg {
            color: var(--chat-close-button-color);
            fill: var(--chat-close-button-color);
            .st0 {
                fill: var(--chat-close-button-color);
            }
            width: 16px;
            height: 16px;
            outline: none !important;
        }

        .back-button-svg {
            color: var(--chat-close-button-color);
            fill: var(--chat-close-button-color);
        }

        .logout-button-svg {
            color: var(--chat-close-button-color);
            fill: var(--chat-close-button-color);
            .st0 {
                fill: var(--chat-close-button-color);
            }
            width: 24px;
            height: 24px;
            outline: none !important;
        }
    }

    .chat-top-layer {
        position: absolute;
        top: 0px;
        width: 100%;
        height: auto;
        z-index: 999;
        display: flex;
        flex-direction: column;
    }

    .chat-description {
        .chat-description-text {
            width: calc(100% - 24px);
            align-self: center;
            font-family: Noto Sans KR, Arial, sans-serif;
            text-align: left;
            letter-spacing: -0.6px;
            font-size: var(--font-size-normal);
            font-weight: 500;
            min-height: 44px;
            padding: 4px 0px;
            white-space: pre-wrap;
            word-break: break-word;
        }
        display: flex;
        max-height: 200px;
        height: auto;
        background-color: white;
        color: #565656;
        padding: 4px 16px;
        border-bottom: 1px solid #bfbfbf;

        .close-button-svg {
            color: #565656;
            fill: #565656;
            width: 16px;
            height: 16px;
            outline: none !important;
        }
    }

    .chat-bottom-menu-background {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 999;
        &.enable {
            background-color: rgba(0, 0, 0, 0.8);
        }
    }

    .chat-bottom-menu {
        position: absolute;
        bottom: 48px;
        width: 100%;
        font-family: Noto Sans KR, Arial, sans-serif;
        font-size: var(--font-size-normal);
        letter-spacing: -0.64px;
        background-color: #ffffff;
        max-height: 0;
        transition: max-height 0.15s ease-out;
        z-index: 999;
        overflow: hidden;

        &.show-footer {
            bottom: 80px;
        }

        &.show {
            max-height: 200px;
            transition: max-height 0.25s ease-in;
        }
    }

    .emoji-input {
        padding: 0;
        position: absolute;
        bottom: 48px;
        width: 100%;
        font-family: Noto Sans KR, Arial, sans-serif;
        font-size: 20px;
        letter-spacing: -0.64px;
        background-color: #ffffff;
        max-height: 0;
        transition: max-height 0.15s ease-out;
        z-index: 999;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        border: none;
        visibility: hidden;

        &.show-footer {
            bottom: 80px;
        }

        &.show {
            max-height: 353px;
            transition: max-height 0.25s ease-in;
            visibility: visible;
        }

        .emoji-item {
            margin: 2px;
            cursor: pointer;
        }
    }

    .chat-suggestions-background {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 999;
    }

    .chat-suggestions {
        position: absolute;
        bottom: 48px;
        width: 100%;
        font-family: Noto Sans KR, Arial, sans-serif;
        font-size: var(--font-size-normal);
        letter-spacing: -0.64px;
        background-color: #ffffff;
        &.show-footer {
            bottom: 80px;
        }

        .chat-suggestions-title {
            height: 40px;
            padding: 0px 20px;
            line-height: 38px;
            border-bottom: solid 0.5px #c4c4c4;
        }

        .chat-suggestions-item {
            display: flex;
            background-color: white;
            min-height: 40px;
            color: black;
            padding: 8px 20px;
            line-height: 1.3;
            border-bottom: solid 0.4px #c4c4c4;
            overflow: hidden;

            &.active {
                background-color: var(--autocomplete-active-color);
            }
        }
    }

    .feedback-modal-button {
        position: absolute;
        width: 170px;
        height: 60px;
        border-radius: 20px;
        background-color: var(--bubble-background-color);
        padding-top: 4px;
        padding-left: 16px;
        padding-right: 16px;
        z-index: 200;
        display: flex;
        justify-content: space-around;
    }

    .feedback-button {
        height: 16px;
        font-family: Noto Sans KR, Arial, sans-serif;
        font-size: 12px;
        letter-spacing: -0.48px;
        margin-left: auto;
        bottom: 50px;
        display: flex;
        justify-content: flex-end;
        align-self: center;
        opacity: 1;
        outline: none !important;
        cursor: pointer;
        color: var(--chat-feedback-text-color);
        background-color: var(--chat-feedback-background-color);
    }

    .chat-input-container {
        background-color: #ffffff;
        flex-shrink: 0;
        height: 48px;
        &.show-footer {
            height: 81px;
        }
        width: 100%;
        margin: 0;
        bottom: 0;
        z-index: 999;
        border-top: 1px solid #dddddd;
    }

    .chat-input-area {
        background-color: var(--chat-input-area-background-color);
        width: 100%;
        height: 48px;
        display: flex;

        .send-button {
            width: 24px;
            height: 24px;
            border: none;
            display: flex;
            align-self: center;
            justify-content: center;
            margin: 0px 8px;
            .button-image {
                width: 24px;
                height: 24px;
                .cls-1 {
                    fill: #505050;
                    fill: var(--chat-input-send-button-color);
                }
                .cls-2 {
                    fill: #ffffff;
                }
            }

            &.disable {
                .button-image {
                    width: 24px;
                    height: 24px;
                    .cls-1 {
                        fill: #c4c4c4;
                    }
                    .cls-2 {
                        fill: #ffffff;
                    }
                }
            }
        }

        .upload-button {
            width: 24px;
            height: 24px;
            border: none;
            display: flex;
            align-self: center;
            justify-content: center;
            margin: 0px 8px;
            .button-image {
                width: 20px;
                height: 24px;
                .cls-1 {
                    fill: #505050;
                    // fill: var(--chat-input-send-button-color);
                }
                .cls-2 {
                    fill: #ffffff;
                }
            }

            &.show {
                .button-image {
                    .cls-1 {
                        fill: #2a68f7;
                    }
                }
            }

            &.disable {
                .button-image {
                    width: 24px;
                    height: 24px;
                    .cls-1 {
                        fill: #c4c4c4;
                    }
                    .cls-2 {
                        fill: #ffffff;
                    }
                }
            }
        }
    }

    .chat-input {
        font-family: Noto Sans KR, Arial, sans-serif;
        font-size: var(--font-size-normal);
        border-radius: 10px;
        height: 36px;
        width: 100%;
        align-self: center;
        background-color: var(--chat-input-background-color);
        padding-left: 0px;
        padding-right: 4px;
        margin-left: 8px;
        border: 1px solid #e9e9e9;
        position: relative;
        display: flex;
        bottom: 0;
        z-index: 999;

        &.disable {
            background-color: #eeeeee;
        }

        .bot-button {
            width: 24px;
            height: 24px;
            border: none;
            display: flex;
            align-self: center;
            justify-content: center;
            margin: 4px;
            .button-image {
                width: 24px;
                height: 24px;
                .cls-1 {
                    fill: #505050;
                    // fill: var(--chat-input-send-button-color);
                }
                .cls-2 {
                    fill: #ffffff;
                }
            }

            &.disable {
                .button-image {
                    width: 24px;
                    height: 24px;
                    .cls-1 {
                        fill: #c4c4c4;
                    }
                    .cls-2 {
                        fill: #ffffff;
                    }
                }
            }
        }

        .emoji-button {
            width: 24px;
            height: 24px;
            border: none;
            display: flex;
            align-self: center;
            justify-content: center;
            margin: 4px;
            .button-image {
                width: 20px;
                height: 24px;
                .cls-1 {
                    fill: #505050;
                    // fill: var(--chat-input-send-button-color);
                }
                .cls-2 {
                    fill: #ffffff;
                }
            }

            &.show {
                .button-image {
                    .cls-1 {
                        fill: #2a68f7;
                    }
                }
            }

            &.disable {
                .button-image {
                    width: 20px;
                    height: 24px;
                    .cls-1 {
                        fill: #c4c4c4;
                    }
                    .cls-2 {
                        fill: #ffffff;
                    }
                }
            }
        }

        input {
            font-family: Noto Sans KR, Arial !important;
            font-size: 16px !important;
            margin: 0;
            border: none;
            width: 100%;
            letter-spacing: -0.64px !important;
            color: var(--chat-input-text-color) !important;
            background-color: transparent !important;
        }

        input:focus {
            outline: none !important;
        }

        input[type="text"]::-ms-clear {
            display: none !important;
        }

        input::-ms-clear {
            display: none !important;
        }

        ::placeholder {
            color: var(--chat-input-placeholder-color);
        }
        ::-webkit-input-placeholder {
            color: var(--chat-input-placeholder-color);
        }
        :-ms-input-placeholder {
            color: var(--chat-input-placeholder-color) !important;
        }
    }

    .chat-time {
        width: auto;
        font-family: Noto Sans KR, Arial, sans-serif;
        color: var(--bubble-time-color);
        margin: 0px 8px 0px 8px;
        font-size: 12px;
        align-self: flex-end;
        white-space: nowrap;

        &.bottom {
            align-self: center;
        }

        &.no-margin {
            margin: 0px;
        }

        &.show-avatar {
            margin-left: 0px;
        }
    }

    .chat-time:focus {
        color: var(--bubble-time-color);
    }

    .bubble-container {
        display: flex;
        flex-direction: column;
        width: auto;
        margin-top: 0;
        margin-bottom: 0;

        &.odd {
            margin-right: 8px;
            margin-top: 12px;
            margin-bottom: 12px;
        }
    }

    .bubble-list {
        display: flex;
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        height: auto;
        padding-bottom: 8px;

        &.has-without-bubble {
            width: 100%;
        }
    }

    .message-container {
        display: flex;
        flex-direction: row;
        width: auto;
        opacity: 1;

        &.odd {
            flex-direction: row-reverse;
        }
        &.last {
            margin-bottom: 16px;
        }

        &.sending {
            opacity: 0.7;
        }

        &.without-bubble {
            width: 100%;

            &.show-avatar {
                margin-left: 0px;
            }
        }
    }

    .bubble-group {
        display: flex;
        height: auto;
        width: 100%;
        justify-content: flex-start;
        overflow: hidden;
        // padding-right: 8px;
        &.odd {
            justify-content: flex-end;
        }
    }

    .suggestion-button {
        font-size: var(--font-size-button);
        max-width: 300px;
        height: 32px;
        min-height: 32px !important;
        line-height: 32px;
        border-radius: 40px;
        font-family: Noto Sans KR, Arial, sans-serif;
        background-color: var(--suggestion-button-background-color);
        color: var(--suggestion-button-text-color);
        border: 1px solid var(--suggestion-button-border-color);
        padding: 4px 16px;
        // box-shadow: 0px 2px 8px 0 rgba(56, 58, 92, 0.12);
        outline: none !important;

        &:hover {
            background-color: var(--suggestion-button-hover-color);
        }

        &.dim {
            background-color: var(--suggestion-button-preparing-background-color);
            color: var(--suggestion-button-preparing-text-color);
            border: 1px solid var(--suggestion-button-preparing-border-color);
        }

        .link-icon {
            align-self: center;
            margin-left: 4px;
            width: 8px;
            height: 8px;

            path {
                fill: var(--suggestion-button-text-color) !important;
            }
            &.dim {
                path {
                    fill: var(--suggestion-button-preparing-text-color) !important;
                }
            }
        }
    }

    .popup {
        padding: 0;
        min-width: 300px;
        max-width: 410px;
        max-height: 520px;
        .popup-title {
            font-family: Noto Sans KR, Arial, sans-serif;
            font-size: var(--font-size-title);
            color: #353535;
            padding: 16px;
            text-align: center;
            font-weight: 300;
            border-bottom: 1px solid #c4c4c4;
        }

        .popup-message {
            text-align: left;
            font-family: Noto Sans KR, Arial, sans-serif;
            font-size: var(--font-size-normal);
            color: #353535;
            padding: 20px 20px 0px 20px;
        }

        .popup-footer {
            margin-top: 16px;
            text-align: center;
        }

        .popup-button {
            font-size: var(--font-size-button);
            width: 140px;
            height: 40px;
            font-family: Noto Sans KR, Arial, sans-serif;
            background-color: var(--popup-button-background-color);
            color: var(--popup-button-text-color);
            border: 1px solid var(--popup-button-border-color);
            padding: 5px 10px;
            border-radius: 2px;
            // box-shadow: 0 2px 5px 0 #d4d4d5, 0 0 0 1px #f4f4f4;
            outline: none !important;
            margin-bottom: 16px;
        }
    }

    .link-button {
        text-decoration: none !important;
        border: none !important;
        background-color: transparent !important;
        display: inline;
        margin: 0;
        padding: 0;
        outline: none !important;
    }

    .date-line {
        font-size: 14px;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background-color: transparent;
        z-index: 900;
        div {
            height: inherit;
            width: auto;
            border: none;
            background-color: gray;
            color: white;
            border-radius: 15px;
            line-height: 30px;
            padding: 0px 20px;
            margin: 0;
        }
    }

    .has-new-message {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center;
        text-align: center;
        z-index: 900;
        background-color: transparent;
        position: absolute;
        bottom: 0;

        button {
            align-self: flex-end;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            margin-top: 8px;
            margin-bottom: 8px;
            opacity: 0.9;
            border: none;
            text-decoration: none;
            font-size: 14px;
            width: auto;
            background-color: var(--chat-title-background-color-start);
            color: white;
            border-radius: 15px;
            padding: 0px 20px;

            &:hover {
                opacity: 1;
            }
        }


    }

    .load-previous-message {
        width: 100%;
        height: 30px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        text-align: center;
        line-height: 30px;
        background-color: transparent;
        z-index: 900;
        margin-top: 8px;
        margin-bottom: 8px;

        .load-previous-message-button {
            height: inherit;
            width: auto;
            border: none;
            background-color: black;
            color: white;
            border-radius: 15px;
            line-height: 30px;
            padding: 0px 20px;
            margin: 0;
            cursor: pointer;
            opacity: 0.2;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    .chat-disconnected {
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background-color: rgba(235, 64, 52, 0.7);
        color: white;
        font-size: 13px;
        z-index: 1001;
    }

    .chat-send-failed {
        width: 20px;
        height: 20px;
        align-self: flex-end;
        margin-right: 9px;
        margin-top: 9px;
        button {
            width: 20px;
            height: 20px;
            border: none;
            background-color: #cc0000;
            color: white;
            border-radius: 10px;
            outline: none;
            line-height: 20px;
            font-weight: 300;
            margin: 0;
            padding: 0;
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 900;
        display: none;
        float: left;
        min-width: 10rem;
        padding: 0.25rem 0;
        margin: 0.125rem 0 0;
        font-size: var(--font-size-normal);
        color: #6c757d;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d9e3e9;
        border-radius: 0.25rem;
    }

    .dropdown-menu-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu.show {
        display: block;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.375rem 1.5rem;
        clear: both;
        font-weight: 300;
        font-size: 13px;
        color: #6c757d;
        text-align: inherit;
        white-space: nowrap;
        text-decoration: none;
        background-color: transparent;
        border: 0;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
        color: #272e37;
        text-decoration: none;
        background-color: #f8f9fa;
    }
    .dropdown-item.active,
    .dropdown-item:active {
        color: #313a46;
        text-decoration: none;
        background-color: #edf1f5;
    }
    .dropdown-item.disabled,
    .dropdown-item:disabled {
        color: #6c757d;
        pointer-events: none;
        background-color: transparent;
    }

    .dropdown-menu.show {
        display: block;
    }

    .dropdown-header {
        display: block;
        padding: 0.25rem 1.5rem;
        margin-bottom: 0;
        font-size: var(--font-size-normal);
        color: inherit;
        white-space: nowrap;
    }

    .dropdown-item-text {
        display: block;
        padding: 0.375rem 1.5rem;
        color: #6c757d;
    }

    .button-multiline {
        white-space: pre-wrap;
        word-break: break-word;
        height: auto !important;
        line-height: 1.4;
        min-height: 40px;
    }

    .modal-content {
        border-radius: 20px !important;
    }

    .clickable-image {
        display: flex;
        img {
            cursor: pointer;
            -webkit-transition: -webkit-filter 1s ease;
            -moz-transition: filter 1s ease;
            -o-transition: filter 1s ease;
            -ms-transition: filter 1s ease;
            transition: filter 1s ease;
            align-self: center;
        }

        img:hover {
            -webkit-filter: brightness(70%);
            -ms-filter: brightness(70%);
            -moz-filter: brightness(70%);
            filter: brightness(70%);
        }
    }

    .ft-slider {
        position: relative;

        display: block;
        box-sizing: border-box;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        // -webkit-touch-callout: none;
        // -khtml-user-select: none;
        // -ms-touch-action: pan-y;
        // touch-action: pan-y;
        // -webkit-overflow-scrolling: touch;
        padding-right: 0px;
        // margin-left: -40px;

        .ft-inner-slider::-webkit-scrollbar {
            display: none;
        }

        .ft-inner-slider {
            -ms-overflow-style: none;
            scrollbar-width: none;

            position: relative;

            display: block;
            box-sizing: border-box;

            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            // -webkit-touch-callout: none;
            // -khtml-user-select: none;
            // -ms-touch-action: pan-y;
            // touch-action: pan-y;
            // -webkit-overflow-scrolling: touch;
            -webkit-tap-highlight-color: transparent;

            overflow: auto;
        }

        .ft-slider-track {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            padding-right: 10px;
            width: max-content;
        }

        .ft-slider-arrow-next {
            position: absolute;
            top: calc(50% - 19px);
            display: block;
            visibility: visible;
            width: 32px;
            height: 32px;
            padding: 0;
            // transform: translate(0, -50%);
            cursor: pointer;
            border: none;
            outline: none;
            background: transparent;
            z-index: 100;
            right: 4px;
            -webkit-transform-style: preserve-3d;
            -webkit-transform: translateZ(0px);
            &.disabled {
                visibility: hidden;
            }
        }

        .ft-slider-arrow-prev {
            position: absolute;
            top: calc(50% - 19px);
            display: block;
            visibility: visible;
            width: 32px;
            height: 32px;
            padding: 0;
            // transform: translate(0, -50%);
            cursor: pointer;
            border: none;
            outline: none;
            background: transparent;
            z-index: 199;
            left: 4px;
            -webkit-transform-style: preserve-3d;
            -webkit-transform: translateZ(0px);
            &.disabled {
                visibility: hidden;
            }
        }

        .ft-slider-arrow {
            background: var(--carousel-arrow-color);
            color: white;
            border-radius: 16px;
            width: 32px;
            height: 32px;
            line-height: 30px;
            opacity: 0.3;
            display: flex;
            justify-content: center;

            .arrow-image {
                align-self: center;
            }

            &.disabled {
                display: none;
            }
        }
    }

    .chat-input-footer {
        background-color: #fafafa;
        height: 32px;
        display: flex;
        justify-content: center;
        margin-top: auto;
        flex-shrink: 0;

        .chat-input-footer-content {
            align-self: center;
            font-size: 12px;
            color: rgba(34, 34, 34, 0.6);
            a {
                color: rgba(34, 34, 34, 0.6);
                font-weight: 500;
                text-decoration: none;
            }
        }
    }

    /* Arrows */
    .slick-prev,
    .slick-next {
        font-size: 0;
        line-height: 0;

        position: absolute;
        top: 50%;

        display: block;

        width: 20px;
        height: 20px;
        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);

        cursor: pointer;

        color: transparent;
        border: none;
        outline: none;
        background: transparent;
    }
    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
        color: transparent;
        outline: none;
        background: transparent;
    }
    .slick-prev:hover:before,
    .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
        opacity: 1;
    }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
        opacity: 0.25;
    }

    .slick-prev:before,
    .slick-next:before {
        font-family: "slick";
        font-size: 20px;
        line-height: 1;

        opacity: 0.75;
        color: white;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .slick-prev {
        left: -25px;
    }
    [dir="rtl"] .slick-prev {
        right: -25px;
        left: auto;
    }
    .slick-prev:before {
        content: "←";
    }
    [dir="rtl"] .slick-prev:before {
        content: "→";
    }

    .slick-next {
        right: -25px;
    }
    [dir="rtl"] .slick-next {
        right: auto;
        left: -25px;
    }
    .slick-next:before {
        content: "→";
    }
    [dir="rtl"] .slick-next:before {
        content: "←";
    }

    /* Dots */
    .slick-dotted.slick-slider {
        margin-bottom: 30px;
    }

    .slick-dots {
        position: relative;
        bottom: 0px;

        display: block;

        width: 100%;
        padding: 0;
        margin: 0;

        list-style: none;

        text-align: center;
    }
    .slick-dots li {
        position: relative;

        display: inline-block;

        width: 20px;
        height: 20px;
        margin: 0 0px;
        padding: 0;

        cursor: pointer;
    }
    .slick-dots li button {
        font-size: 0;
        line-height: 0;

        display: block;

        width: 20px;
        height: 20px;
        padding: 0px;

        cursor: pointer;

        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
    }
    .slick-dots li button:hover,
    .slick-dots li button:focus {
        outline: none;
    }
    .slick-dots li button:hover:before,
    .slick-dots li button:focus:before {
        opacity: 1;
    }
    .slick-dots li button:before {
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;

        position: absolute;
        top: 0;
        left: 0;

        width: 20px;
        height: 20px;

        content: "•";
        text-align: center;

        opacity: 0.25;
        color: black;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: black;
    }

    /* Slider */
    .slick-slider {
        position: relative;

        display: block;
        box-sizing: border-box;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;

        display: block;
        overflow: hidden;

        margin: 0;
        padding: 0;
    }
    .slick-list:focus {
        outline: none;
    }
    .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        top: 0;
        left: 0;

        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
    }
    .slick-track:before,
    .slick-track:after {
        display: block;

        content: "";
    }
    .slick-track:after {
        clear: both;
    }
    .slick-loading .slick-track {
        visibility: hidden;
    }

    .slick-slide {
        display: none;
        float: left;

        min-height: 1px;
    }
    [dir="rtl"] .slick-slide {
        float: right;
    }

    // .slick-slide img
    // {
    //     display: block;
    // }

    .slick-slide.slick-loading img {
        display: none;
    }
    .slick-slide.dragging img {
        pointer-events: none;
    }
    .slick-initialized .slick-slide {
        display: block;
    }
    .slick-loading .slick-slide {
        visibility: hidden;
    }
    .slick-vertical .slick-slide {
        display: block;

        height: auto;

        border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
        display: none;
    }

    .slick-slide {
        margin: 0px 8px 0px 0px;
        display: block !important;
        justify-content: center;
        align-items: center;
    }

    .slick-thumb li {
        width: 60px;
        height: 45px;
    }

    .slick-arrow-next {
        position: absolute;
        top: calc(50% - 19px);
        right: 12px;
        display: block;
        width: 32px;
        height: 32px;
        padding: 0;
        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
        z-index: 199;
        right: 20px;
        -webkit-transform-style: preserve-3d;
        -webkit-transform: translateZ(0px);
        visibility: visible;
    }

    .slick-arrow-prev {
        position: absolute;
        top: calc(50% - 19px);
        display: block;
        width: 32px;
        height: 32px;
        padding: 0;
        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
        z-index: 199;
        left: 0px;
        -webkit-transform-style: preserve-3d;
        -webkit-transform: translateZ(0px);
        visibility: visible;
    }

    .slick-arrow {
        background: var(--carousel-arrow-color);
        color: white;
        border-radius: 16px;
        width: 32px;
        height: 32px;
        font-size: 15px;
        font-family: Noto Sans KR, Arial, sans-serif;
        text-align: center;
        line-height: 30px;
        opacity: 0.3;
        display: flex;
        justify-content: center;

        .arrow-image {
            align-self: center;
        }

        &.disabled {
            opacity: 0.15;
        }
    }

    .delete-icon-button {
        text-decoration: none !important;
        border: none !important;
        background-color: transparent !important;
        display: inline;
        margin: 0;
        padding: 0;
        outline: none;
        color: gray;

        &:hover {
            text-decoration: none;
            outline: none;
            color: red;
        }
    }

    .intro-list {
        max-height: calc(100% - 32px);
        overflow: hidden;
    }
}
